/*
 * Copyright 2024 ByOmakase, LLC (https://byomakase.org)
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {Destroyable} from '../types';

export class DestroyUtil {

  public static destroy(...destroyables: (Destroyable | undefined)[]) {
    destroyables.forEach(destroyable => {
      if (destroyable) {
        try {
          destroyable.destroy();
        } catch (e) {
          // console.error(e);
        }
      }
    })
  }

  public static nullify(...nullifiables: any[]) {
    nullifiables.forEach(nullifiable => {
      if (nullifiable) {
        try {
          // @ts-ignore
          nullifiable = void 0;
        } catch (e) {
          console.error(e);
        }
      }
    })
  }

}

export function nullifier(...nullifiables: any[]) {
  DestroyUtil.nullify(...nullifiables);
}

export function destroyer(...destroyables: (Destroyable | undefined)[]) {
  DestroyUtil.destroy(...destroyables);
}
